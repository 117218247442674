


const studentInfoFields = {
    fullName: "Full Name",
    givenName: "Given Name",
    familyName: "Family Name",
    dob: "Date of Birth",
    emailAddress: "Email Address",
}


const typeSelectionTypes = [
    {
        comment: "Do not import this field (Default)",
        key: "skip",
        display: "Skip"
    },
    {
        comment: "Used to associate attributes with a student",
        key: "identifier",
        display: "Student Identifier",
        
    },
    {
        comment: "Information about a student (e.g. Name, Email, DOB)",
        key: "info",
        display: "Student Information",
        
    },
    {
        comment: "Assessment results or other details",
        key: "attribute",
        display: "Student Attribute",
        
    },
    
]

export { studentInfoFields, typeSelectionTypes }