<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">"{{ fieldName}}"</p>
            <button class="delete" @click="close()" aria-label="close"></button>
        </header>

        <section class="modal-card-body">
            <div class="content">
                <p>Which {{ mode }} is "{{ fieldName }}"?</p>

                <tags-input element-id="tags" v-model="selectedTags" :add-tags-on-blur="true"
                    :typeahead-always-show="true" :hide-input-on-limit="true" :limit="1" :existing-tags="tagsAvailable"
                    :typeahead="true" />

            </div>
        </section>

        <section class="modal-card-body">
            <div class="content">
                <p>Link {{ fieldName }} to a date?</p>
                <label class="checkbox">
                    <input type="checkbox" v-model="hasDate">
                    Link to a date?
                </label>

            </div>
        </section>


        <footer class="modal-card-foot is-justify-content-right">
            <button @click="close()" class="button is-danger">Cancel</button>
            <button @click="back()" class="button is-warning">Back</button>
            <button @click.prevent="next()" :disabled="selectedTags.length == 0" class="button is-success">Next</button>
        </footer>

    </div>
</template>

<script>

import Vue from 'vue'
import  VoerroTagsInput from  '@voerro/vue-tagsinput';
Vue.component('tags-input', VoerroTagsInput);

export default {
    name: "modal-import-identifier-field-selector",
    props: {
        fieldName: String,
        currentValue: Object,

        mode: {
            default: "Identifier",
            type: String
        },
        url: {
            default: "v2/staff/db/identifiers/",
            type: String
        }
    },

    data: function () {
        return {
            selectedTags: [],
            tagsAvailable: [],
            hasDate: false,
            date: undefined,
        }
    },

    methods: {
        next() {
            console.log(this.selectedTags)
            this.$emit("tagSelected", this.selectedTags)
        },

        close: async function () {
            this.$emit('closeModal')
        },

        back: async function () {
            this.$emit('resetModal')
        }
    },

    mounted() {

        const that = this
        let seen = false;
        const name = this.fieldName

        this.$api.get(this.url, true).then(x => {


            that.tagsAvailable = x.map(y => {
                if (y.data.name == name) {
                    seen = y._id
                }
                return { key: y._id, value: y.data.name }
            })

            if (seen === false) {
                that.tagsAvailable.unshift({ key: "", value: name })
                console.log(["Adding name to av"])
            }
        }).then(() => {
            console.log([this.currentValue, "this is the current value for xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"])
            if (this.currentValue.value) {
                this.selectedTags = [this.currentValue]
            }
            else {

                this.selectedTags = [{ key: (seen === false) ? "" : seen, value: name }]
            }


            this.hasDate = (this.currentValue.date ?? false) ? true : false
            this.date = this.currentValue.date ?? (new Date()).toISOString()

            console.log(this.selectedTags)
        })

    }
}

</script>