<template>

  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">"{{ fieldName}}"</p>
      <button class="delete" @click="close()" aria-label="close"></button>
    </header>

    <section class="modal-card-body">
      <div class="content">
        <p>Which field is "{{ fieldName }}"?</p>
        <dl>
          <dt v-for="(siVal, siKey) in studentInfo" :key="siKey" class="">
            <label class="radio has-text-weight-bold">
              <input v-model="localStateParam" type="radio" name="studentInfoChoice" :value="siKey">
              {{siVal}}
            </label>
          </dt>
        </dl>
      </div>
    </section>

    <footer class="modal-card-foot is-justify-content-right">
      <button @click="close()" class="button is-danger">Cancel</button>
      <button @click="back()" class="button is-warning">Back</button>
      <button @click.prevent="next()" :disabled="!localStateParam" class="button is-success">Next</button>
    </footer>

  </div>
</template>

<script>
import { studentInfoFields } from 'helpers/config'

//var instance;








export default {
  name: "modal-student-info-import-field-selector",
  props: ['currentValue', 'fieldName'],



  mounted() {
    // instance = this;
    console.log(["mounted", studentInfoFields, this.currentValue])
    this.localStateParam = this.currentValue.key
  },



  methods: {

    close: async function () {
      this.$emit('closeModal')
    },

    back: async function () {
      this.$emit('resetModal')
    },

    next: async function () {
      console.log(["xxxxx", this.localStateParam])
      this.$emit('tagSelected', { mode: "info", key: this.localStateParam, value: studentInfoFields[this.localStateParam]})
    },
  },






  data: function () {
    return {

      localStateParam: null,

      studentInfo: studentInfoFields,





    }
  },


}

</script>
