<template>
  <div id="app is-align-content-center">
    <div class="container ">
      <!--UPLOAD-->
      <form enctype="multipart/form-data" novalidate v-if="!csvData && (isInitial || isSaving)">
        <h1>Upload Files (currently supports CSV only)</h1>
        <div class="dropbox">
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept="text/csv" class="input-file">
            <p v-if="isInitial">
              Drag your file here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading files.
            </p>
        </div>
      </form>
      <!--SUCCESS-->
      <div v-if="csvData && csvData.length" class="is-align-content-center">
        <h2>Uploaded file successfully.</h2>
        <div class="table-container is-align-content-center">

            <table class="table is-bordered is-striped is-hoverable">
                <thead>
                    <tr>
                        <th rowspan=2>Field</th>
                        <th rowspan=2>Action</th>
                        <th :colspan="Math.min(10, csvData.length)" class="has-text-centered">Records (max first 10)</th>
                    </tr>
                    <tr>
                        <th v-for="x in Math.min(10, csvData.length)" class="has-text-centered" :key='x'>{{x}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="header in csvHeaders" :key="header">
                        <th><a @click="chooseFieldType(header)">{{ header }}</a></th>
                        <td>{{ getCurrentOption(header)[0] }}<span v-if=getCurrentOption(header)[1]> / {{ getCurrentOption(header)[1] }}</span></td>
                        <td v-for="x in Math.min(10, csvData.length)" class="has-text-centered" :key="x">{{ csvData[x][header] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>
          <a href="javascript:void(0)" @click="reset()">Upload again</a>
        </p>
        <ModalImportFieldSector :vis="showModal" v-on:close-modal-import-field-selector="closeModal" v-on:modal-field-mapping="mapField" :currentOptions="currentOptions"   :fieldName="currentField"></ModalImportFieldSector>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
  // swap as you need
  // import { upload } from './file-upload.fake.service'; // fake service
  // import { upload } from './file-upload.service';   // real service
  // import { wait } from './utils';

var instance;

import Papa from "papaparse"
import ModalImportFieldSector from "../components/ModalImportFieldSelector.vue"

  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
  export default {
    name: 'app',
    components: {
        ModalImportFieldSector
    },

    data() {
      return {
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'inputFiles',
        csvData: null,
        csvHeaders: null,
        showModal: false,
        currentField: null,
        mapping: {},
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },
      currentOptions() {

        return this.mapping[this.currentField] || {}

      }
    },
    methods: {

      getCurrentOption(x) {
          if (!this.mapping[x] || this.mapping[x].mode == "skip") {
            return ["Skip"]
          }

          console.log(this.mapping[x])


          return [this.mapping[x].mode, this.mapping[x].value + (this.mapping[x].key == "" ? "*" : "")]
      },
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
        this.csvData = null;
        this.csvHeaders = null;
        this.mapping = {};
      },
      closeModal() {
          this.showModal = false;
      },
      chooseFieldType(fieldName) {

          this.showModal = true;
          this.currentField = fieldName;
      },
      mapField(mapValue) {

          this.mapping[mapValue[0]] = mapValue[1]
          this.showModal = false;
          console.log([mapValue, this.mapping, "QWQWTQTWQTWTQWTQTWQTTQW"])
      },

      async filesChange(fieldName, fileList) {
        // handle file changes

        if (fieldName != this.uploadFieldName) return;

        if (!fileList.length) return;

        Papa.parse(fileList[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                instance.csvHeaders = results.meta.fields.filter(n => n);

                console.log(instance.csvHeaders)
                instance.csvData = results.data;
                instance.mapping = instance.csvHeaders.reduce((p, c) => {
                  p[c] = { mode: "skip"}
                  return p
                }, {})
            }
        });

      }
    },
    mounted() {
      this.reset();
      instance = this;
    },
  }


</script>

<style>
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>