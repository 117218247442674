<template>

    <div id="modal-ter" class="modal" :class="vis ? 'is-active' : ''">
        <div class="modal-background"></div>

        {{ currentOptions }}



        <StudentInfoSelector
                                v-if="localMode == modes.studentInfoSelector"
                                :fieldName="fieldName"
                                :currentValue="currentOptions"
                                v-on:closeModal="close"
                                v-on:resetModal="back"
                                v-on:tagSelected="onStudentInfo"        />



        <ImportMapToKey
                                v-else-if="localMode == modes.studentAttributeSelector"
                                v-on:tagSelected="attributeSelected"
                                v-on:resetModal="back"
                                v-on:closeModal="close"
                                :currentValue="currentOptions"
                                mode="Attribute"
                                url="v2/staff/db/attributes/"
                                :fieldName="fieldName"              />

        <ImportMapToKey
                                v-else-if="localMode == modes.studentIdentifierSelector"
                                v-on:tagSelected="identifierSelected"
                                v-on:resetModal="back"
                                v-on:closeModal="close"
                                :currentValue="currentOptions"
                                mode="Identifier"
                                url="v2/staff/db/identifiers/"
                                :fieldName="fieldName"              />

        <ImportTypeSelectorVue
                                v-else
                                :fieldName="fieldName"
                                v-on:closeModal="close"
                                :localOptions="localOptions"
                                v-on:selectOption="optionSelected"        />





    </div>

</template>

<script>



import { studentInfoFields, typeSelectionTypes } from 'helpers/config'
import StudentInfoSelector from "./StudentInfoSelector.vue"
import ImportMapToKey from "../components/school/ImportMapToKey.vue"
import ImportTypeSelectorVue from './school/ImportTypeSelector.vue';

var instance;

const defaultSettings = { mode: "skip", key: null, value: null }






export default {
    name: "modal-import-field-selector",
    props: ['fieldName', 'vis', 'currentOptions'],

    components: {
        StudentInfoSelector,
        ImportMapToKey,
        ImportTypeSelectorVue
    },

    data: function () {
        return {
            localOptions: defaultSettings,

            localStateParam: null,
            localMode: 0,
            localIdentifiers: null,
            addingNewIdentifierButtonEnabled: false,
            addingNewIdentifierProcessor: "",
            newIdentifierInput: "",
            addNewIdentifierInputEnabled: true,
            localAttributes: null,
            addingNewAttributeButtonEnabled: false,
            addingNewAttributeProcessor: "",
            newAttributeInput: "",
            addNewAttributeInputEnabled: true,
            warningText: "",
            studentInfo: studentInfoFields,
            studentIdentifier: null,
            typeSelection: typeSelectionTypes,
            modes: {
                start: 0,
                studentInfoSelector: "info",
                studentIdentifierSelector: "identifier",
                studentAttributeSelector: "attribute",
            },

            studentAttribute: undefined,





        }
    },

    watch: {
        fieldName() {
            this.reset("watch")

        }

    },



    mounted() {
        instance = this;
        this.reset("mounted")




    },

    methods: {

        reset(xx) {

            console.log(["Current Option", this.currentOptions, this.fieldName, xx])

            Object.keys(defaultSettings).forEach((index) => {
                this.localOptions[index] = this.currentOptions?.[index] ?? defaultSettings[index];
            })

            console.log(["Local Option", this.localOptions, this.fieldName, xx])
            this.localMode = this.modes.start;

        },


        optionSelected: function (val) {

            if (val === "skip") {
                this.$emit('modal-field-mapping', [this.fieldName, { mode: "skip" }]);
                return
            }

            this.localMode = val

        },

        identifierSelected: function (val) {
            console.log([val, "identifierSelected"])
            this.$emit('modal-field-mapping', [this.fieldName, { mode: "identifier", key: val[0].key, value: val[0].value }]);
            return
        },


        attributeSelected: function (val) {
            console.log([val, "identifierSelected"])
            this.$emit('modal-field-mapping', [this.fieldName, { mode: "attribute", key: val[0].key, value: val[0].value }]);
            return
        },


        onStudentInfo: function (val) {
            console.log(['student info field', val])

            this.$emit('modal-field-mapping', [this.fieldName, val])
            return
        },

        addNewIdentifier: async function () {

            this.addNewIdentifierInputEnabled = false;
            this.addingNewIdentifierProcessor = "is-loading"

            this.newIdentifierInput = this.newIdentifierInput.trim()

            let aq = await this.$api.post("v2/staff/db/identifiers/", true, { name: this.newIdentifierInput });

            if (aq._id) {
                this.addingNewIdentifierProcessor = "";
                this.addNewIdentifierInputEnabled = true;
                this.localIdentifiers.push(aq)
                this.localStateParam = aq._id;
                this.newIdentifierInput = ""
                return
            }

            this.warningText = aq.description;
            this.addingNewIdentifierProcessor = "";
            this.addNewIdentifierInputEnabled = true;

            setTimeout(() => {
                instance.warningText = ""
            }, 4000)

            //console.log(aq)
        },

        addNewAttribute: async function () {

            this.addNewAttributeInputEnabled = false;
            this.addingNewAttributeProcessor = "is-loading"

            this.newAttributeInput = this.newAttributeInput.trim()

            let aq = await this.$api.post("v2/staff/db/attributes/", true, { name: this.newAttributeInput });

            if (aq._id) {
                this.addingNewAttributeProcessor = "";
                this.addNewAttributeInputEnabled = true;
                this.localAttributes.push(aq)
                this.localStateParam = aq._id;
                this.newAttributeInput = ""
                return
            }

            this.warningText = aq.description;
            this.addingNewAttributeProcessor = "";
            this.addNewAttributeInputEnabled = true;

            setTimeout(() => {
                instance.warningText = ""
            }, 4000)

            //console.log(aq)
        },


        close: async function () {
            //console.log(this.localState)
            this.localStateParam = null
            this.$emit('close-modal-import-field-selector')
            this.addingNewIdentifierProcessor = "";
            this.newIdentifierInput = ""
            this.addingNewAttributeProcessor = "";
            this.newAttributeInput = ""
            this.localStateMode = "skip"
        },
        back: function () {
            this.localMode = this.modes.start;
        },



    }
}

</script>