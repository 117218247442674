<template>
     <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">"{{ fieldName}}"</p>
                <button class="delete" @click="close()" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
                <div class="content" >
                    <p>How should field "{{ fieldName }}" be handled?</p>
                    <div class="control">

                        <dl>
                            <div v-for="val in typeSelection" :key="val.key">
                                <dt class="">
                                    <label class="radio has-text-weight-bold">
                                        <input v-model="localStateMode" name="modeRadio" type="radio" :value="val.key">
                                        {{ val.display}}
                                    </label>
                                </dt>
                                <dd>
                                    {{ val.comment }}
                                </dd>
                            </div>



                        </dl>
                    </div>
                </div>




            </section>
            <footer class="modal-card-foot is-justify-content-right">
                <button @click="close()" class="button is-danger">Cancel</button>
                <button @click.prevent="next()" class="button is-success">Next</button>
            </footer>
        </div>
</template>

<script>

import { typeSelectionTypes } from 'helpers/config'

export default {
    name: "modal-import-type-selector",
    props: {
        fieldName: String,
        localOptions: Object
    },

    mounted() {
        console.log(["xxxx", this.localOptions.mode, "mounted" ])
        this.localStateMode = this.localOptions.mode
    },

    data: function () {
        return {
            selectedTags: [],
            tagsAvailable: [],
            typeSelection: typeSelectionTypes,
            localStateMode: null
        }
    },

    methods: {

        close: async function () {
            this.$emit('closeModal')
        },

        back: async function () {
            this.$emit('resetModal')
        },

        next: async function () {
            this.$emit('selectOption', this.localStateMode)
        },
    },


}

</script>